import React from "react";
import "./viewService.css";
import { TiDelete } from "react-icons/ti";
import { BiSolidQuoteSingleLeft } from "react-icons/bi";
import { BiSolidQuoteLeft, BiSolidQuoteRight } from "react-icons/bi";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { RxScissors } from "react-icons/rx";

function BeforeServiceView({ popUpState, cost }) {
  const handlePopUp = () => {
    popUpState(false);
  };

  return (
    <div className="beforeServiceViewMainOuter">
      <div className="beforeServiceViewMainInner">
        <div className="beforeServiceViewMainContent">
          <div className="popUpUnSetContainer">
            <TiDelete className="popUpUnSet" style={{color: "#d6bbaf"}} size={33} onClick={handlePopUp} />
          </div>
          <div className="beforeServiceViewMainContentLeft">
            {/* <div className="quotesTopContainer">
              <BiSolidQuoteLeft size={40} className="quotesTopContainerLeft" />
            </div>
            <div className="planeTopContainer">
              <BiSolidQuoteRight size={40} className="planeTopContainerLeft" />
            </div> */}
            {/* <p className="beforeServiceViewMainContentLeftPtag">
              Kindly use M-Pesa Detail Displayed for payments at the moment
              while we work on the automated payment method. We appreciate your
              patience!"
            </p> */}
            <div className="beforeServiceLogo2">
              <div className="navbar-brand2">
                <div className="logoBox2">
                  <h3>B</h3>
                  <div className="logoDivider"></div>
                  <h3>S</h3>
                  {/* className="logoBox2H3" */}
                  <div className="logoBox2PTag">
                    <p>BEYOND THE SAVANNAH</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="beforeServiceViewMainContentLeftPtag">
              <p>
                "Please use the M-Pesa details provided for payments at this
                time, while we work on implementing an automated payment method.
                We appreciate your patience!"
              </p>
            </div>
          </div>
          <div className="beforeServiceViewMainContentRight">
            <div className="beforeServiceViewMainContentRightContent">
              <div>
                <div className="beforeServiceViewMainContentRightContentTop">
                  <h2>Payment Process</h2>
                  {/* <RxScissors size={29} className="beforeServiceViewMainContentRightContentIcon"/> */}
                </div>

                <div className="paymentSetContainer">
                  <div className="paymentSet">
                    <div className="paymentSetInner">
                      <span className="paymentSetInner1Span">
                        Paybill Number :{" "}
                      </span>
                      <span>4139143</span>
                    </div>
                  </div>
                  <div className="paymentSet">
                    <div className="paymentSetInner">
                      <span className="paymentSetInner1Span">Account: </span>
                      <span>Your name as it appears in your ID card</span>
                    </div>
                  </div>
                  <div className="paymentSet">
                    <div className="paymentSetInner">
                      <span className="paymentSetInner1Span">
                        Amount To Be Paid:{" "}
                      </span>
                      <span>{cost}</span>
                    </div>
                  </div>
                  <div className="paymentSet">
                    <div className="paymentSetInner">
                      <span className="paymentSetInner1Span">Recipient: </span>
                      <span>
                        [ Beyond Annah Consulting Limited ] should appear as the
                        recipient
                      </span>
                    </div>
                  </div>
                </div>

                <div className="paymentSet paymentSetNote">
                  <div className="paymentSetInner paymentSetInnerNote">
                    <span className="paymentSetInner1Span">NOTE: </span>
                    <span>
                      Remember to send an email before and after the payment is
                      made.
                    </span>
                  </div>
                  <div className="paymentSetInner paymentSetInnerNote">
                    <span className="paymentSetInner1Span">NOTE: </span>
                    <span>
                    If you’re unable to use M-Pesa services, please email us for alternative payment options.
                    </span>
                  </div>
                  <div class="paymentSetInner paymentSetInnerNote">
                    <span class="paymentSetInner1Span">Email: </span>
                      <span style={{textTransform: "lowercase", textDecoration: "underline"}}>
                        info@beyondthesavannah.co.ke
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeforeServiceView;
